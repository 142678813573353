<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">Create a new device from template</h4>
      <p class="category">Create a device</p>
    </md-card-header>
    <div class="container-fluid">
      <div class="d-flex justify-center">
        <div class="col-lg-4 col-12 m-5">
          <div class="row d-flex align-center m-0 mb-5">
            <div class="col col-10">
              <v-select
                label="Select template"
                hide-details="auto"
                placeholder="Select template"
                v-model="selectedTemplate"
                :items="templates"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </div>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  size="20"
                  class="col col-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline</v-icon
                >
              </template>
              <span>{{
                $t("tooltips.deviceTemplateForm.selectTemplate")
              }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="d-flex justify-center">
        <div class="col-10">
          <template-details
            v-if="selectedTemplate"
            :selectedTemplate="selectedTemplate"
          ></template-details>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-10">
        <md-card>
          <md-card-header :data-background-color="getTheme">
            <h4 class="title">Device information</h4>
          </md-card-header>
          <md-card-content>
            <div class="container-fluid">
              <v-form ref="form">
                <div class="row">
                  <div class="col col-12 col-md-6">
                    <div class="row d-flex m-0 align-center">
                      <div class="col col-10">
                        <v-text-field
                          label="alias"
                          v-model="form.alias"
                          :rules="[rules.required]"
                        />
                      </div>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            size="20"
                            class="col col-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information-outline</v-icon
                          >
                        </template>
                        <span>{{ $t("tooltips.deviceForm.alias") }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div class="col col-12 col-md-6">
                    <div class="row d-flex m-0 align-center">
                      <div class="col col-10">
                        <v-text-field
                          label="identifier"
                          v-model="form.identifier"
                          :rules="[rules.required]"
                        />
                      </div>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            size="20"
                            class="col col-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information-outline</v-icon
                          >
                        </template>
                        <span>{{ $t("tooltips.deviceForm.identifier") }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </v-form>
              <div class="row">
                <div class="col-12 text-right">
                  <md-button
                    @click="() => handleSubmit('createAnother')"
                    class="md-raised"
                    :class="[`md-${getTheme}`]"
                  >
                    {{ $t("buttons.saveAndCreateAnother") }}
                  </md-button>
                  <md-button
                    @click="() => handleSubmit('goBack')"
                    class="md-raised"
                    :class="[`md-${getTheme}`]"
                  >
                    {{ $t("buttons.saveAndGoToDevice") }}
                  </md-button>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </md-card>
</template>
<script>
import form from "@/mixins/form";
import { mapGetters } from "vuex";

import TemplateDetails from "@/components/Templates/TemplateDetails";
export default {
  components: { TemplateDetails },
  name: "NewTemplateDashboard",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      alias: "",
      identifier: "",
      mac: "",
    },
    editMode: false,
    templates: [],
    selectedTemplate: null,
  }),
  mounted() {
    this.getTemplates();
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  methods: {
    handleSubmit(stay) {
      if (this.$refs.form.validate()) {
        if (this.editMode) {
          this.editItem(stay);
        } else {
          this.createDeviceFromTemplate(stay);
        }
      }
    },
    cleanForm() {
      this.form.alias = "";
      this.form.identifier = "";
      this.form.mac = "";
    },
    createDeviceFromTemplate(stay) {
      this.$store
        .dispatch("template/createDeviceFromTemplate", {
          deviceTemplate: this.selectedTemplate,
          device: {
            deviceTemplate: this.selectedTemplate,
            alias: this.form.alias,
            identifier: this.form.identifier,
            mac: this.form.identifier,
          },
        })
        .then((device) => {
          if (device) this.$toast.success(this.$t("toast.createSuccess"));
          this.handleNextAction(stay, device.id);
        })
        .catch((err) => {
          this.$toast.error("algo ocurrio");
          console.error(err);
        });
    },
    goBackWithDelay(device) {
      setTimeout(() => {
        this.$router.push({
          name: "deviceProfile",
          params: { device: device },
        });
      }, 1000);
    },
    handleNextAction(stay, device) {
      switch (stay) {
        case "createAnother":
          this.cleanForm();
          this.$refs.form.resetValidation();
          this.form.role_id = 1;
          break;
        case "stay":
          this.editMode = true;
          if (this.$route.name !== "dashboardEdit") {
            this.$router.push({
              name: "dashboardEdit",
            });
          }
          break;
        case "goBack":
          this.goBackWithDelay(device);
          break;
      }
    },
    getTemplates(page = 1) {
      this.$store
        .dispatch("template/getTemplates", {
          page: page,
          filter: "",
        })
        .then((data) => {
          this.templates = data.data;
        });
    },
  },
};
</script>