<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <tgl-breadcrumbs :items="breadcrumbs" />
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <create-device-from-selected-template></create-device-from-selected-template>
    </div>
  </div>
</template>

<script>
import CreateDeviceFromSelectedTemplate from "@/components/Templates/CreateDeviceFromSelectedTemplate.vue";
import TglBreadcrumbs from "@/components/UI/TglBreadcrumbs";

export default {
  components: {
    CreateDeviceFromSelectedTemplate,
    TglBreadcrumbs,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: "Crear nuevo dispositivo",
          disabled: true,
          to: { name: "devices" },
        },
      ];
    },
  },
};
</script>